<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="title"
      class="elevation-1"
      loading-text="Loading... Please wait"
      :search="search"
      :custom-filter="filterText"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-1">
          <v-row class="d-flex mx-1">
            <span class="text-h6 primary--text">COUNTRIES</span>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="text-capitalize"
                  :loading="loading"
                  @click="createModal"
                  elevation="0"
                  fab
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus-thick</v-icon>
                </v-btn>
              </template>
              <span>Create new</span>
            </v-tooltip>
          </v-row>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-divider></v-divider>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="my-1">
          <v-btn
            color="grey"
            class="mx-1"
            fab
            x-small
            dark
            @click="editItem(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            color="error"
            class="mx-1"
            fab
            x-small
            dark
            @click="deleteItem(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="550px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline error--text text--darken-1">{{
              formTitle
            }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="my-n2">
                <v-autocomplete
                  v-model="editedItem.country_id"
                  :items="countries"
                  label="Select country"
                  :item-text="countryText"
                  item-value="id"
                  color="grey"
                  dense
                  outlined
                  class="d-flex align-end"
                  :rules="rules.select"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="my-n5">
                <v-textarea
                  v-model="editedItem.description"
                  outlined
                  name="input-7-4"
                  label="Tender Details"
                  color="grey"
                  dense
                  :rules="rules.required"
                  class="mb-n3"
                >
                  <template v-slot:label>
                    <span class="input__label"
                      >Document Description
                      <v-icon small color="error" class="mt-n2"
                        >mdi-star-outline</v-icon
                      ></span
                    >
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="text-capitalize mx-1"
              dark
              @click="close"
            >
              Cancel <v-icon small>mdi-cancel</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="text-capitalize mx-1"
              :loading="loading"
              @click="save"
            >
              Submit <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SupportedCountryService from "../../services/supported_countries.service";

export default {
  components: {},
  data: () => ({
    name: "TenderCategory",
    search: "",
    loading: true,
    items: [],
    countries: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    remember: 0,
    timeout: 2000,
    dialog: false,
    valid: true,
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
    },
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Flag",
        value: "country.emoji",
      },
      {
        text: "Name",
        value: "country.name",
      },
      {
        text: "Currency",
        value: "country.currency",
      },
      {
        text: "Region",
        value: "country.region",
      },
      {
        text: "Description",
        value: "description",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.getAllData();
    this.getAllCountries();
  },
  methods: {
    countryText(item) {
      return `${item.emoji} ${item.name}`;
    },
    createModal() {
      this.dialog = true;
    },
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    getAllCountries() {
      return SupportedCountryService.getAllCountries().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.countries = response.data.data;
            console.log(this.countries);
          } else {
            this.roles = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.countries = [];
          console.log(error);
        }
      );
    },
    openDocument(item) {
      console.log(item);
      this.$router.replace(item.attachment);
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getAllData() {
      return SupportedCountryService.index().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.items.splice(index, 1)) {
        SupportedCountryService.delete(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            if (error.response.status == 401) {
              this.handleLogout();
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleFileUpload(file) {
      console.log("uploading...");
      await this.getBase64(file).then(
        (data) => (this.editedItem.attachment = data)
      );
      //   this.$tender_service.addDocument(formData).then(
      //     (response) => {
      //       console.log(response);
      //       if (response.status == 200) {
      //         this.$store.dispatch("alert/success", "Tender Document uploaded!");
      //         this.getTender();
      //         this.loading = false;
      //       } else {
      //         this.$store.dispatch("alert/error", response.message);
      //         this.loading = false;
      //       }
      //     },
      //     (error) => {
      //       this.loading = false;
      //       if (error.response.status == 422) {
      //         console.log(error.response.data.errors);
      //         this.message = error.response.data.message;
      //       } else {
      //         this.message =
      //           (error.response && error.response.data) ||
      //           error.message ||
      //           error.toString() ||
      //           "Internal Server Error";
      //       }
      //       this.$store.dispatch("alert/error", this.message);
      //       this.loading = false;
      //     }
      //   );
    },

    save() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          SupportedCountryService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.getAllData();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.getAllData();
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.getAllData();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.getAllData();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          // create
          SupportedCountryService.store(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Item created successfully"
                );
                this.loading = false;
                this.getAllData();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.getAllData();
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.getAllData();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.getAllData();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      } else {
        this.loading = false;
        this.getAllData();
      }
    },
  },
};
</script>